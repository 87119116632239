.modal{
    position: fixed;
    z-index: 999999;
    top: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(59, 64, 69, 0.705);;
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal-container{
    height: 50%;
    width: 50%;
    background-color: rgb(59,64,69);
    border-radius: 20px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.modal-title{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #ffffff;
}

.modal-title > hr {
    border: 2px solid var(--lightblue);
    border-radius: 2px;
    width: 20%;
    margin-top: 10px;
}

.modal-details, .cont{
    display: flex;
    flex-direction: column;
    color: #ffffff;
    align-items:  center ;
}

.modal-details{
    margin-top: 10px;
}

svg{
    margin-right: 10px;
}

.modal-footer{
    margin-bottom: 0;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}

.btmod{
    float: left;
    background-color: var(--lightblue);
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
}

@media all and (max-width: 480px) {
    .modal-container{
        height: 50%;
        width: 90%;
    }
    
}
