.google-map-code {
    height: fit-content;
    background-color: #DFDFDF;  
    width: 100%;
    height: 100%;
}

.google-map-code > iframe{
    width: 100%;
    height: 100%;
}