.navbar {
    background: transparent;
    transition: 50ms;
    height: 100px;
}

.navbar.active{
    background: #ffffff;
    transition: 50ms;
    height: 70px;
}

nav{
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: space-between;
    align-items: center;
    color: #000000;
    font-family: 'Averia Serif Libre', cursive;
    position: fixed;
    top: 0;
    z-index: 999999999;
}

nav > .brand,
nav >.brand > a{
    height: 100%;
    display: flex;
    align-items: center;
    height: 100%;
    font-size: 24px;
}

nav >.brand > a > img{
    height: 70%;
    margin: 10px;
}

nav > .navlinks{
    list-style: none;
    display: flex;
    margin: 10px;
}

nav > .navlinks > .navlink, 
nav > .navlinks > .navbtn{
    height: 100%;
    padding: 10px;
}

nav > .navlinks > .navlink:hover{
    color: var(--lightblue);
    border-bottom: 2px solid var(--lightblue);
    transition: 50ms;
}

nav > .navlinks > .navbtn{
    background: var(--lightblue);
    color: #ffffff;
    border-radius: 20px;
    width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
}


 
@media all and (min-width: 480px) and (max-width: 768px) {
    nav{
        height: 50px;
        justify-content: space-between;
        padding-top: 10px;
        position: relative;
    }
    
    nav > .brand{
        font-size: 14px;
    }
    
    nav >.brand > a > img{
        height: 45px;
        margin: 10px;
    }
    
    /* nav > .navlinks > .navlink{
        display: none;
    }
     */
    nav > .navlinks > .navlink, 
    nav > .navlinks > .navbtn{
        height: 100%;
        padding: 5px;
        font-size: 11px;
    }

    nav > .navlinks > .navbtn{
        background: var(--lightblue);
        border-radius: 20px;
        width: 70px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
 
@media all and (max-width: 480px) {
    nav{
        height: 50px;
        justify-content: space-between;
        padding-top: 10px;
        position: relative;
    }
    
    nav > .brand{
        font-size: 14px;
    }
    
    nav >.brand > a > img{
        height: 45px;
        margin: 10px;
    }
    
    nav > .navlinks > .navlink{
        display: none;
    }
    
    nav > .navlinks > .navlink, 
    nav > .navlinks > .navbtn{
        height: 100%;
        padding: 5px;
    }

    nav > .navlinks > .navbtn{
        background: var(--lightblue);
        border-radius: 20px;
        width: 70px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 11px;
    }
}

