.contact{
    height: 50vh;
    display: flex;
    padding: 25px 5;
}

.contact-container{
    padding: 1rem 0;
    height: 100%;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.contact-container > h1 {
    text-transform: uppercase;
    font-style: bolder;
    font-weight: 1400;
    font-size: 15px;
    line-height: 119.02%;
    letter-spacing: 0.09rem;

    color: #000000;
}

.contact-container > div {
    padding: 0.3rem;
}

.contact-container > div > a {
    text-decoration: none;
    color: #000000;
}

.map{
    height: 100%;
    width: 50%;
}

/* @media all and (min-width: 480px) and (max-width: 768px) {

    
    
} */
 
@media all and (max-width: 480px) {
    .contact{
        height: 50vh;
        display: flex;
        flex-direction: column;
        padding: 25px 5;
    }
    .contact-container, .map{
        height: 100%;
        width: 100%;
    }

}
