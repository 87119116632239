:root{
  --lightblue: #2895D5
}

*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

a{
  text-decoration: none;
  color: inherit;
}

body {
  background-image: url(./assets/images/blur-hospital.jpg);
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: 100% 100%;
  font-family: 'Playfair Display', serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Playfair Display', serif;

}

p{
  margin-top: 5px;
}

html {
  scroll-behavior: smooth;
}
