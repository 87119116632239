.banner{
    background:  rgba(139, 139, 139, 0.295);
    position: relative;
    top: 0;
    display: flex;
    height: 100vh;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.banner > .banner-container{
    position: relative;
    display: flex; 
    background: #ffffff9f;
    box-shadow: -10px 5px 10px rgb(17, 17, 17);
    height: 65%;
    width: 80%;
    align-items: center;
    justify-content: space-between;
    border-radius: 10px;
    padding: 20px;
}

.banner > .banner-container > .banner-image{
    height: 100%;
    width: 40%;
    border-radius: 10px;
    overflow: hidden;
}

.banner > .banner-container > .banner-image > img{
    height: 100%;
    width: 100%;
    margin-right: 20px;
    border-radius: 10px;
}

.banner > .banner-container > .banner-det{
    width: 80%;
    font-family: 'Averia Serif Libre';
    font-style: normal;
    font-weight: 400;
    line-height: 29px;
    color: #000000;
    padding: 15px 30px;
}


.banner > .banner-container > .banner-det > p > ol > li::marker {
    display: inline-block;
    margin-left: 20px;
    padding: 0;
}

.btn{
    margin-bottom: 20px;
    background: var(--lightblue);
    border-radius: 20px;
    width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    float: right;
}

@media all and (min-width: 480px) and (max-width: 768px) {

    .banner > .banner-container > .banner-det{
        width: 70%;
        font-family: 'Averia Serif Libre';
        font-style: normal;
        font-weight: 400;
        line-height: .85em;
        color: #000000;
        padding: 5px 10px;
    }
    
    
}
 
@media all and (max-width: 480px) {
    .banner{
        margin-top: 10px;
        position: relative;
        display: flex;
        height: fit-content;
        width: 100%;
        align-items: center;
        justify-content: center;
    }
    
    .banner > .banner-container{
        position: relative;
        display: flex;
        flex-direction: column;
        background: #D9D9D9;
        height: 90%;
        width: 90%;
        align-items: center;
        justify-content: space-between;
        border-radius: 10px;
        padding: 10px;
    }
    
    .banner > .banner-container > .banner-image{
        height: 40vh;
        width: 100%;
        border-radius: 10px;
        overflow: hidden;
    }
    
    .banner > .banner-container > .banner-image > img{
        height: 100%;
        width: 100%;
        margin-right: 20px;
        border-radius: 10px;
    }
    
    .banner > .banner-container > .banner-det{
        width: 100%;
        font-family: 'Averia Serif Libre';
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        font-size: 14px;
        color: #000000;
        padding: 5px 10px;
    }
    
    
    .banner > .banner-container > .banner-det > p > ol > li::marker {
        display: inline-block;
        margin-left: 20px;
        padding: 0;
    }
    
    .btn{
        margin-bottom: 5px;
        background: var(--lightblue);
        border-radius: 20px;
        width: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #ffffff;
        float: right;
    }
    
}

