.services{
    height: 80vh;
    width: 100%;
    font-size: 14px;
    padding: 40px;
    background-color: #ffffff;
}

.services > h1{
    text-align: center;
}

.service-items{
    height: 100%;
    width: 100%;
    padding: 30px;
    display: grid;
    grid-template-columns: auto auto auto auto;
    column-gap: 10px;
    justify-items: center;
    align-items: center;

}

.service-item{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    color: #000000;
    overflow: hidden;
    border-radius: 5px;
    height: 100%;
    width: 100%;
}


.service-item > img{
    width: 100%;
    height: 60%;
}

.service-item > .details{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    padding: 15px;
}

.service-item > .details > h1 {
    font-size: 25px;
    font-weight: 600;
}


@media all and (min-width: 480px) and (max-width: 768px) {

    
    
}
 
@media all and (max-width: 480px) {

    .services{
        height: fit-content;
    }
    
    .service-items{

        display: grid;
        grid-template-columns: auto;
        height: fit-content;
        padding: 10px;
    
    }

    .service-item{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        color: #000000;
        overflow: hidden;
        border-radius: 5px;
        height: 100%;
        width: 100%;
    }
}

