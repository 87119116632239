.about{
    background-color: #DFDFDF;  
    padding: 40px;
}

.about div{
    display: inline;
}

.about hr {
    width: 8%;
    margin-top: 10px;
    border: 2px solid var(--lightblue);
}


.about h1{
    font-size: 3rem;
    font-style: normal;
    font-weight: 100;
    text-transform: capitalize;
}

.about h1 span {
    color: var(--lightblue);;
    font-weight: 700;
}

.about > p,
.des{
    font-size: 20px;
    font-weight: lighter;
}

.div{
    margin: 0px 20px;
    display: none;
}

ol{
    list-style-position: inside;
}