.footer{
    padding: 1rem 0;
    background-color: #000000;
}

.footer> p{
    font-style: normal;
    font-weight: 700;
    text-align: center;
    font-size: 10px;
    line-height: 119.02%;
    /* or 20px */

    letter-spacing: 0.09rem;

    color: #ffffff;
}